import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { MainBanner } from "../components/banners/main-banner"

import { MainLayout } from "../components/layouts/layout"
import MainSection from "../components/sections/main-section"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <MainLayout activeNav="/koncept">
      <SEO
        title="Konceptet omkring jysk køkkenfornyelse"
        description="Hør hvad jyskkøkkenfornyelse er, og hvorfor vi gør som vi gør. Vi har stort fokus miljøet og mindskelsen af resursespild"
      />
      <MainBanner
        image={data.banner.childImageSharp.base64.base64}
        title="konceptet"
        description="Et koncept med fokus på bæredygtighed"
      />
      <Container fluid>
        <MainSection>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
                <h1>MANGLER TEKST</h1>
              </Col>
            </Row>
          </Container>
        </MainSection>
      </Container>
    </MainLayout>
  )
}

export const query = graphql`
  {
    banner: file(relativePath: { eq: "banners/kitchen_black.jpg" }) {
      id
      childImageSharp {
        base64: sizes(base64Width: 1600, maxHeight: 800, quality: 100) {
          base64
        }
      }
    }
  }
`
